.switch {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 20px;
	background-color: #198754;
	border-radius: 20px;
}

.switch::after {
	content: '';
	position: absolute;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	background-color: white;
	top: 1px;
	left: 1px;
	transition: all 0.3s;
}

.checkbox:checked + .switch::after {
	left: 20px;
}

.checkbox:checked + .switch {
	background-color: #ffc107;
}

.checkbox {
	display: none;
}
