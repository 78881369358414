.Navbar-link {
	margin: 0px 3vw;
	color: gray;
	text-decoration: none;
	border: none;
	background: none;
}

.Navbar-link:hover {
	cursor: pointer;
	color: rgb(60, 60, 60);
}

.Navbar-link.Navbar-brand {
	color: black;
	font-weight: bolder;
}

.Navbar-link.active {
	color: black;
	font-weight: bold;
}
