img.Content-poster {
	width: 30vw;
	max-width: 540px;
	padding: 5px;
}

h1.Content-title {
	font-size: 3em;
}

h2.Content-tagline {
	font-size: 2.5em;
}

h3.Content-tag {
	display: inline-block;
	margin: 0.5em 1em;
	font-size: 1.5em;
}

.Content-data {
	font-size: 1.25em;
	padding: 5px;
}

.Content-video {
	border-radius: 15px;
	margin: 5px;
}
